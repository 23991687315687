<template>
  <div :class="['wood', 'page-' + (activeIndex - 1)]">
    <div class="swiper-wrap">
      <swiper ref="popupSwiper" :options="popupOptions">
        <swiper-slide class="slide"> </swiper-slide>
        <swiper-slide class="slide">
          <Tab1 @open-popup="openPopup"></Tab1>
        </swiper-slide>
        <swiper-slide class="slide">
          <Tab2 @open-popup="openPopup"></Tab2>
        </swiper-slide>
        <swiper-slide class="slide">
          <Tab3 @open-popup="openPopup" @open-interview="interviewVisible = true"></Tab3>
        </swiper-slide>
        <swiper-slide class="slide">
          <Tab4 :lists="schoolList" @open-popup="openPopup"></Tab4>
        </swiper-slide>
        <swiper-slide class="slide">
          <Tab5 @open-popup="openPopup"></Tab5>
        </swiper-slide>
        <swiper-slide class="slide">
          <Tab6></Tab6>
        </swiper-slide>
      </swiper>
      <div class="title" :class="['pos-' + activeIndex]">{{ title }}</div>
      <div class="swiper-pagination"></div>
    </div>
    <!-- 弹框 -->
    <transition name="bounce">
      <Popup :visible.sync="visible" v-if="visible" :popup-info="popupInfo"></Popup>
    </transition>
    <transition name="bounce">
      <Interview :visible.sync="interviewVisible" :videos="interviewList" v-if="interviewVisible"></Interview>
    </transition>
  </div>
</template>

<script>
import Tab1 from "./components/Tab-1";
import Tab2 from "./components/Tab-2";
import Tab3 from "./components/Tab-3";
import Tab4 from "./components/Tab-4";
import Tab5 from "./components/Tab-5";
import Tab6 from "./components/Tab-6";
import Popup from "./components/Popup.vue";
import Interview from "./components/Interview";

import { getInfo, getInterview } from "@/api";

export default {
  components: { Tab1, Tab2, Tab3, Tab4, Tab5, Tab6, Popup, Interview },
  data() {
    const _this = this;
    return {
      activeIndex: 1,
      visible: false,
      interviewVisible: false,
      popupOptions: {
        initialSlide: 1,
        allowTouchMove: true,
        direction: "vertical",
        on: {
          slideChange: function (a) {
            if (this.activeIndex == 0) {
              _this.$router.replace("/");
            }
            _this.activeIndex = this.activeIndex + 1;
          },
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      listQuery: {
        pageSize: 9999,
        pageNum: 1,
        company: "YEZI",
        busiType: "XQHZ",
      },
      titles: ["教师团队", "教学环境", "课程介绍", "校企合作", "大事件", "联系我们"],
      popupInfo: null,
      schoolList: [],
      interviewList: [],
    };
  },
  computed: {
    title() {
      return this.titles[this.activeIndex - 2];
    },
  },
  methods: {
    // 校企合作
    getInfo() {
      getInfo(this.listQuery).then((result) => {
        const { content } = result;
        this.schoolList = content;
      });
    },
    // 采访资料
    getInterview() {
      getInterview({ company: "YEZI" }).then((result) => {
        this.interviewList = result;
      });
    },
    openPopup(info) {
      this.popupInfo = info;
      this.visible = true;
    },
  },
  created() {
    this.getInfo();
    this.getInterview();
  },
};
</script>

<style lang="scss" scoped>
.wood {
  // position: relative;
  height: 100%;
  .title {
    font-size: rem(36);
    right: rem(90);
    font-weight: bold;
    letter-spacing: 2px;
    position: absolute;
    transition: top 0.5s;
    z-index: 99;
    color: #aeaeae;
    &.pos-1 {
      top: rem(35+3);
    }
    &.pos-2 {
      top: rem(72+3);
    }
    &.pos-3 {
      top: rem(112+3);
    }
    &.pos-4 {
      top: rem(150+3);
    }
    &.pos-5 {
      top: rem(188+3);
    }
    &.pos-6 {
      top: rem(223+3);
    }
    &.pos-7 {
      top: rem(255+3);
    }
  }
  .swiper-wrap {
    height: 100%;
    position: relative;
    background: #000;
    background-size: 100% 100%;
    .swiper-container {
      height: 100%;
    }
  }
  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    right: rem(40);
    top: rem(40);
    ::v-deep {
      .swiper-pagination-bullet {
        margin: rem(8) 0;
        background: #555555;
        opacity: 1;
        width: rem(22);
        height: rem(22);
      }
      .swiper-pagination-bullet-active {
        background: #00ff00;
      }
    }
    // --swiper-theme-color: #ff6600;
    // --swiper-pagination-color: #fac82b; /* 两种都可以 */
  }
}
</style>
