<template>
  <div class="popup" @touchmove.stop.prevent>
    <div class="close" @click="closePopup">
      <img src="../../../pc/wood/imgs/close.png" alt="" />
    </div>
    <swiper ref="popupSwiper" :options="popupOptions">
      <swiper-slide class="slide" v-for="(item, index) in popupInfo.files" :key="index">
        <img class="slide-image" :src="item.url" alt="" />
      </swiper-slide>
    </swiper>
    <div class="paging">
      <img class="arrow" @click="slidePrev()" :src="require('@/views/pc/wood/imgs/r-1.png')" alt="" />
      <span class="number">{{ activeIndex }}</span>
      <img class="arrow" @click="slideNext()" :src="require('@/views/pc/wood/imgs/l-1.png')" alt="" />
    </div>
    <div class="desc">
      <h2>{{ popupInfo.title }}</h2>
      <p v-if="popupInfo.desc">{{ popupInfo.desc }}</p>
      <div class="html-info" v-else v-html="popupInfo.html"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    popupInfo: {
      typeof: Object,
      default: () => {
        return {
          files: [],
          title: "",
          desc: "",
        };
      },
    },
  },
  data() {
    const _this = this;
    return {
      activeIndex: 1,
      popupOptions: {
        // allowTouchMove: false,
        on: {
          slideChange: function (a) {
            _this.activeIndex = this.activeIndex + 1;
          },
        },
      },
    };
  },
  computed: {
    popupSwiper() {
      return this.$refs.popupSwiper.$swiper;
    },
  },
  methods: {
    closePopup() {
      this.$emit("update:visible", false);
    },
    slidePrev() {
      this["popupSwiper"].slidePrev();
    },
    slideNext() {
      this["popupSwiper"].slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba($color: #000000, $alpha: 0.9);
  left: 0;
  top: 0;
  .close {
    position: absolute;
    right: rem(90);
    top: rem(119);
    &:active {
      opacity: 0.7;
    }
    img {
      width: rem(80);
    }
  }
  .paging {
    width: rem(420);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .arrow {
      width: rem(26);
    }
    .number {
      color: #8954ec;
      font-size: rem(45);
      font-weight: 500;
    }
  }
  .desc {
    margin-top: rem(120);
    h2 {
      font-size: rem(72);
      font-weight: bold;
      color: #fff;
      padding: 0 rem(180);
      box-sizing: border-box;
    }
    p {
      font-size: rem(36);
      color: #9a9a9a;
      padding: 0 rem(170);
      box-sizing: border-box;
      margin-top: rem(60);
      line-height: 28px;
    }
    .html-info {
      font-size: rem(36);
      color: #9a9a9a;
      padding: 0 rem(170);
      margin-top: rem(60);

      ::v-deep p {
        &:nth-of-type(1) {
          transform: rotate(-2deg);
          margin-top: rem(20);
        }
        &:nth-of-type(2) {
          transform: rotate(-1deg);
          margin-top: rem(30);
        }
        &:nth-of-type(3) {
          transform: rotate(2deg);
          margin-top: rem(30);
        }
        &:nth-of-type(4) {
          transform: rotate(1deg);
          margin-top: rem(20);
        }
      }
    }
  }
}
.swiper-container {
  margin: rem(304) auto 0;
}
.swiper-slide {
  position: relative;
  img {
    display: block;
    width: rem(1000);
    margin: 0 auto;
  }
}
.page-3 {
  .popup {
    .swiper-container {
      margin: rem(255) auto 0;
    }
    .swiper-slide {
      position: relative;
      img {
        width: rem(800);
        display: block;
        margin: 0 auto;
      }
    }
    .paging {
      display: none;
    }
    .desc {
      margin-top: rem(80);
      p {
        padding: 0 rem(170);
        box-sizing: border-box;
        margin-top: rem(30);
      }
    }
  }
}
.page-2 {
  .popup {
    .swiper-container {
      margin: rem(255) auto 0;
    }
    .swiper-slide {
      position: relative;
      img {
        width: rem(800);
        display: block;
        margin: 0 auto;
      }
    }
    .paging {
      // display: none;
      margin: rem(80) auto 0;
    }
    .desc {
      margin-top: rem(80);
      p {
        padding: 0 rem(170);
        box-sizing: border-box;
        margin-top: rem(30);
      }
    }
  }
}
</style>
