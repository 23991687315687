<template>
  <div class="tab-five">
    <section class="background-wrap">
      <img src="../imgs/leaf-1.png" />
      <img src="../imgs/leaf-3.png" />
    </section>
    <div class="intro-info">
      <p>{{ axisInfos[active]["desc"] }}</p>
      <img :src="axisInfos[active].url" alt="" />
    </div>
    <div class="time-aixs">
      <div class="line">
        <div class="circular">
          <div class="time">
            <ul class="year" :class="{ ['year-' + active]: true }">
              <li :class="[active == index && 'active']" v-for="(item, index) in axisInfos" :key="index" @click="change(index)">
                {{ item.year }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventUtil } from "@/utils";
export default {
  data() {
    return {
      active: 3,
      axisInfos: [
        {
          year: "2019年07月",
          desc: "叶子学堂承办了腾讯课堂上海线下沙龙，并最终取得了圆满成功。",
          url: require("@/views/pc/leaf/imgs/ev-01.png"),
        },
        {
          year: "2019年08月",
          desc: "由叶子学堂牵头开展的第一次校企合作顺利结课。学员就业率100%，并收获了业内外一致好评。",
          url: require("@/views/pc/leaf/imgs/ev-02.png"),
        },
        {
          year: "2019年12月",
          desc: "叶子学堂获得腾讯课堂“金课堂”奖。线上课程3.0版更新上线，在线课程完课率达到80%。",
          url: require("@/views/pc/leaf/imgs/ev-03.png"),
        },
        {
          year: "2020年08月",
          desc: "叶子学堂5周年线下活动开启。活动中分享了新木团队的项目经验。叶子学堂还正式开启了产学结合的探索，并取得了关键阶段的成功。",
          url: require("@/views/pc/leaf/imgs/ev-04.png"),
        },
        {
          year: "2020年10月",
          desc: "经过数月准备，叶子学堂旗下“动特效教研团队”正式上线。",
          url: require("@/views/pc/leaf/imgs/ev-05.png"),
        },
        {
          year: "2021年10月",
          desc: "叶子学堂全面开启“校企合作新模式”，旗下学员的互联网大厂入职率达到95%，业内外口碑再次提升。",
          url: require("@/views/pc/leaf/imgs/ev-06.png"),
        },
      ],
    };
  },
  methods: {
    change(index) {
      this.active = index;
    },
    handleTouchYear() {
      const el = document.querySelector(".tab-five");
      EventUtil.listenTouchDirection(
        el,
        true,
        null,
        () => {
          if (this.active == 0) return;
          this.active--;
        },
        null,
        () => {
          if (this.active == this.axisInfos.length - 1) return;
          this.active++;
        }
      );
    },
  },
  mounted() {
    this.handleTouchYear();
  },
};
</script>

<style lang="scss" scoped>
.tab-five {
  height: 100%;
  position: relative;
  overflow: hidden;
  .intro-info {
    height: rem(800);
    transform: rotate(-4deg);
    position: absolute;
    top: rem(620);
    right: rem(-20);
    img {
      width: rem(1000);
      transform: translateX(100%);
      transition: transform 0.3s;
    }
    p {
      font-size: rem(36);
      line-height: rem(70);
      font-weight: 600;
      color: #e0dfdf;
      transform: rotate(-2deg);
      padding: rem(20) rem(0) rem(20) rem(200);
      box-sizing: border-box;
      width: rem(800);
      transform: translateX(-200%) rotate(-3deg);
      transition: transform 0.3s;
    }
  }
  .time-aixs {
    .line {
      width: 120vw;
      height: 2px;
      // background: #8954ec;
      position: absolute;
      top: rem(490);
      transform: rotate(-5deg) translateX(rem(-30));
      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 0;
        background: #8954ec;
        z-index: -1;
        transition: width 0.6s;
      }
      .circular {
        width: rem(50);
        height: rem(50);
        background: #8954ec;
        border-radius: 50%;
        position: absolute;
        left: rem(370);
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid #e0dfdf;
        .time {
          width: 100vw;
          height: rem(80);
          left: rem(-370);
          position: absolute;
          bottom: rem(-90);
          mask: linear-gradient(-90deg, transparent, #fff 50%);
          .year {
            width: 150vw;
            position: absolute;
            top: rem(0);
            transition: transform 1s;
            transform: rotate(10deg);
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.year-0 {
              transform: translateX(25vw);
            }
            &.year-1 {
              transform: translateX(0vw);
            }
            &.year-2 {
              transform: translateX(-25vw);
            }
            &.year-3 {
              transform: translateX(-50vw);
            }
            &.year-4 {
              transform: translateX(-75vw);
            }
            &.year-5 {
              transform: translateX(-100vw);
            }
            li {
              font-size: rem(42);
              font-weight: bold;
              width: 25vw;
              color: #555;
              text-align: center;
              transform: rotate(-4deg) translateY(20px);
              opacity: 0;
              transition: transform 0.5s, opacity 0.3s;
              &.active {
                font-size: rem(47);
                color: #8954ec;
              }
            }
          }
        }
      }
    }
  }
  .background-wrap {
    img {
      position: absolute;
      &:nth-of-type(1) {
        width: rem(582);
        top: rem(-89);
        left: rem(114);
        z-index: 2;
        transform-origin: -25% 70%;
        transform: rotate(-90deg);
        transition: transform 0.3s 0.3s;
      }
      &:nth-of-type(2) {
        width: rem(876);
        bottom: rem(186);
        left: rem(34);
        z-index: -1;
        transform-origin: -50% 80%;
        transform: rotate(90deg);
        transition: transform 0.3s 0.3s;
      }
    }
  }
}
.swiper-slide-active {
  .tab-five {
    .intro-info {
      img {
        transform: translateX(rem(-10));
        transition-delay: 0.3s;
      }
      p {
        transform: translateX(0) rotate(-3deg);
        transition-delay: 0.3s;
      }
    }
    .time-aixs {
      .line {
        &::after {
          transition-delay: 0.2s;
          width: 100%;
        }
        .circular {
          .year {
            li {
              transform: rotate(-4deg) translateY(0px);
              opacity: 1;
              &:nth-of-type(1) {
                transition-delay: 0.2s;
              }
              &:nth-of-type(2) {
                transition-delay: 0.3s;
              }
              &:nth-of-type(3) {
                transition-delay: 0.4s;
              }
              &:nth-of-type(4) {
                transition-delay: 0.5s;
              }
              &:nth-of-type(5) {
                transition-delay: 0.6s;
              }
              &:nth-of-type(6) {
                transition-delay: 0.7s;
              }
            }
          }
        }
      }
    }
    .background-wrap {
      img {
        &:nth-of-type(1) {
          transform: rotate(0deg);
          transition-delay: 0.4s;
        }
        &:nth-of-type(2) {
          transform: translateY(0);
          transition-delay: 0.4s;
        }
        &:nth-of-type(3) {
          transform: scale(1);
          opacity: 1;
          transition-delay: 0.5s;
        }
      }
    }
  }
}
</style>
