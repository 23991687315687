<template>
  <div class="tab-two">
    <img src="../imgs/teaching.png" alt="" />
    <img src="../imgs/room.png" alt="" @click="openPopup" />
    <img src="../imgs/text.png" alt="" />
    <img src="../imgs/shst.png" alt="" />
    <div class="text">
      <p>叶子学堂上海校区坐落于上海市杨浦区长阳创谷头部地段。</p>
      <p>作为叶子学堂的核心校区，这里经常会有网易、完美等 国内一线公司来队交流考察。</p>
      <p>教学体系完备，课程内容来自于在运营项目的经验总结。</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popupInfo: {
        files: [
          { url: require("@/views/pc/leaf/imgs/school-1.png") },
          { url: require("@/views/pc/leaf/imgs/school-2.png") },
          { url: require("@/views/pc/leaf/imgs/school-3.png") },
          { url: require("@/views/pc/leaf/imgs/school-4.png") },
          { url: require("@/views/pc/leaf/imgs/school-5.png") },
          { url: require("@/views/pc/leaf/imgs/school-6.png") },
          { url: require("@/views/pc/leaf/imgs/school-7.png") },
        ],
        title: "教学环境",
        desc: "叶子学堂实体校区开设在上海市杨浦区，校区地段优渥，归属于具有“创业者品质生活目的地”之称的“长阳创谷科技园区”。区内环境优雅，商业商业设施完备。周边临近哔哩哔哩、叠纸、完美等优质厂商。是GUI学习的理想场所。",
      },
    };
  },
  methods: {
    openPopup(item, index) {
      this.$emit("open-popup", { ...this.popupInfo });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-two {
  height: 100%;
  padding: rem(144);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  img {
    position: absolute;
    &:nth-of-type(1) {
      width: rem(708);
      left: rem(42);
      top: rem(261);
      transform-origin: -50% 90%;
      transform: rotate(-90deg);
      transition: transform 0.3s;
    }
    &:nth-of-type(2) {
      width: rem(1253);
      left: rem(-83);
      top: rem(215);
      opacity: 0;
      transition: opacity 0.4s;
    }
    &:nth-of-type(3) {
      width: rem(501);
      left: rem(79);
      top: rem(1353);
      transform: scale(1.5);
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
    }
    &:nth-of-type(4) {
      width: rem(297);
      left: rem(112);
      top: rem(1373);
      transform: scale(1.5);
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
    }
  }
  .text {
    position: absolute;
    top: rem(1500);
    p {
      font-size: rem(33);
      color: #9a9a9a;
      width: 95%;
      margin-bottom: rem(40);
      font-weight: 500;
      opacity: 0;
      transition: transform 0.3s, opacity 0.4s;
      &:nth-of-type(1) {
        transform: translateY(20px) rotate(0);
      }
      &:nth-of-type(2) {
        transform: translateY(20px) rotate(1deg);
      }
      &:nth-of-type(3) {
        transform: translateY(20px) rotate(3deg);
      }
    }
  }
}
.swiper-slide-active {
  .tab-two {
    img {
      &:nth-of-type(1) {
        transform: rotate(0deg);
        transition-delay: 0.3s;
      }
      &:nth-of-type(2) {
        opacity: 1;
        transition-delay: 0.5s;
      }
      &:nth-of-type(3) {
        transform: scale(1);
        transition-delay: 0.7s;
        opacity: 1;
      }
      &:nth-of-type(4) {
        transform: scale(1);
        opacity: 1;
        transition-delay: 0.7s;
      }
    }
    .text {
      position: absolute;
      top: rem(1500);
      p {
        opacity: 1;
        transition-delay: 0.4s;
        &:nth-of-type(1) {
          transform: translateY(0px) rotate(0deg);
          transition-delay: 0.4s;
        }
        &:nth-of-type(2) {
          transform: translateY(0px) rotate(1deg);
          transition-delay: 0.5s;
        }
        &:nth-of-type(3) {
          transform: translateY(0px) rotate(3deg);
          transition-delay: 0.6s;
        }
      }
    }
  }
}
</style>
