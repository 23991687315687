<template>
  <div class="popup" @touchmove.stop>
    <div class="close" @click="closePopup">
      <img src="../../../pc/wood/imgs/close.png" alt="" />
    </div>
    <div class="content">
      <h2>
        {{ videos[active]["title"] }}
      </h2>
      <div class="video-wrap">
        <video controls height="100%" controlslist="nodownload" :src="videos[active]['videoFile']['url']"></video>
      </div>
    </div>
    <div class="tabs-wrap">
      <div class="tabs">
        <div class="item-wrap">
          <div
            :class="[active == index && 'active', 'item']"
            v-for="(item, index) in videos"
            :key="index"
            @click="active = index"
          >
            <img :src="item.imageFile.url" alt="" />
          </div>
        </div>
      </div>
      <img class="left" src="../imgs/left.png" @click="slidePrev" alt="" />
      <img class="right" src="../imgs/right.png" @click="slideNext" alt="" />
    </div>
  </div>
</template>

<script>
import { EventUtil } from "@/utils";

export default {
  props: {
    videos: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      active: 0,
    };
  },

  methods: {
    closePopup() {
      this.$emit("update:visible", false);
    },
    slidePrev() {
      let active = this.active;
      active > 0 && active--;
      this.active = active;
    },
    slideNext() {
      let active = this.active;
      active < this.videos.length - 1 && active++;
      this.active = active;
    },
    handleTouchYear() {
      const el = document.querySelector(".tabs");
      EventUtil.listenTouchDirection(
        el,
        true,
        null,
        () => {
          console.log(1);
          if (this.active == 0) return;
          this.active--;
        },
        null,
        () => {
          if (this.active == this.videos.length - 1) return;
          this.active++;
        }
      );
    },
  },
  mounted() {
    // this.handleTouchYear();
  },
};
</script>

<style lang="scss" scoped>
.popup {
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba($color: #000000, $alpha: 0.9);
  left: 0;
  top: 0;
  .close {
    position: absolute;
    right: rem(90);
    top: rem(119);
    &:active {
      opacity: 0.7;
    }
    img {
      width: rem(80);
    }
  }
  .content {
    margin: rem(410) auto 0;
    padding: 0 rem(45);
    h2 {
      font-size: rem(46);
      color: #f2f2f2;
      padding-bottom: rem(27);
      border-bottom: 1px solid #58bc17;
      padding-right: rem(50);
      box-sizing: border-box;
      margin-bottom: rem(50);
    }
    .video-wrap {
      height: rem(562);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }
  .tabs-wrap {
    padding: 0 rem(84);
    margin-top: rem(130);
    position: relative;
    height: rem(180);
    overflow: hidden;
    .tabs {
      // width: 100%;
      overflow: hidden;
      height: 100%;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      .item-wrap {
        height: 100%;
        white-space: nowrap;

        .item {
          // float: left;
          width: 33.33333%;
          display: inline-block;
          height: 100%;
          overflow: hidden;
          position: relative;
          &.active {
            border: 1px solid #ccc;
            box-sizing: border-box;
          }

          img {
            width: 90%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3D(-50%, -50%, 0);
          }
        }
      }
    }
    .left {
      width: rem(29);
      position: absolute;
      left: rem(20);
      top: rem(0);
      top: 50%;
      transform: translateY(-50%);
    }
    .right {
      width: rem(29);
      position: absolute;
      right: rem(20);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
