<template>
  <div class="tab-six">
    <img src="../imgs/leaf-4.png" />
    <img src="../imgs/leaf-5.png" />
    <img src="../imgs/yz-text.png" />
    <img src="../../../pc/leaf/imgs/room.jpg" />
    <div class="info">
      <p>校区地址：上海市杨浦区大桥街道长阳路1687号长阳创谷1号楼1单元410室</p>
      <p>联系信箱：JIANGWEI@leafstudio.cn</p>
      <p><a style="color: #fff" target="_blank" href="https://yezi.ke.qq.com">腾讯课堂：https://yezi.ke.qq.com</a></p>
      <p class="wechat"><span>微信联系：</span><img src="../../../pc/leaf/imgs/wechat.png" alt="" /></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codes: [
        { label: "叶子公众号", url: require("../imgs/code-1.png") },
        { label: "娜娜老师", url: require("../imgs/code-2.png") },
        { label: "rose老师", url: require("../imgs/code-3.png") },
        { label: "贝贝老师", url: require("../imgs/code-4.png") },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.tab-six {
  & > img {
    &:nth-of-type(1) {
      width: rem(953);
      position: absolute;
      top: rem(175);
      left: rem(-9);
      z-index: 1;
      transform: translateX(-120%);
      transition: transform 0.3s;
    }
    &:nth-of-type(2) {
      width: rem(778);
      position: absolute;
      top: rem(118);
      left: rem(41);
      z-index: 11;
      transform: translateX(-120%);
      transition: transform 0.3s;
    }
    &:nth-of-type(3) {
      width: rem(318);
      position: absolute;
      top: rem(520);
      left: rem(64);
      z-index: 12;
      opacity: 0;
      transition: opacity 1s;
    }
    &:nth-of-type(4) {
      width: rem(794);
      position: absolute;
      top: rem(565);
      left: rem(39);
      z-index: 13;
      transform: translateX(-150%) rotate(-50deg);
      transition: transform 0.3s;

    }
  }

  .info {
    position: absolute;
    top: rem(1300);
    padding: 0 0 0 rem(50);
    color: #fff;
    font-size: rem(30);
    p {
      line-height: rem(60);
      transform: translateY(20px);
      transition: transform 0.3s, opacity 0.3s;
      opacity: 0;
    }
    .wechat {
      span {
        float: left;
      }
      img {
        width: rem(150);
        float: left;
      }
    }
  }
}
.swiper-slide-active {
  .tab-six {
    img {
      &:nth-of-type(1) {
        transition-delay: 0.3s;
        transform: rotate(0deg);
      }
      &:nth-of-type(2) {
        transition-delay: 0.4s;
        transform: rotate(0deg);
      }
      &:nth-of-type(3) {
        opacity: 1;
        transition-delay: 0.4s;
      }
      &:nth-of-type(4) {
        transition-delay: 0.5s;
        transform: translateX(0) rotate(-20deg);
      }
    }
  }
  .line {
    &::after {
      transition-delay: 0.5s;
      width: 100%;
    }
  }
  .info {
    p {
      opacity: 1;
      transform: translateY(0px);

      &:nth-of-type(1) {
        transition-delay: 0.3s;
      }
      &:nth-of-type(2) {
        transition-delay: 0.4s;
      }
      &:nth-of-type(3) {
        transition-delay: 0.5s;
      }

      &:nth-of-type(4) {
        transition-delay: 0.6s;
      }
    }
  }
}
</style>
