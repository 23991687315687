<template>
  <div class="tab-one">
    <section class="background-wrap">
      <!-- <img src="../imgs/leaf-1.png" /> -->
      <img src="../imgs/leaf-1.png" />
    </section>
    <section class="people-info">
      <div class="name">
        <span class="na">{{ photos[activeIndex - 1]["name"] }}</span>
        <span class="wk">{{ photos[activeIndex - 1]["work"] }}</span>
        <img class="line" src="../imgs/line.png" alt="" />
        <img class="teacher" src="../imgs/teacher.png" alt="" />
        <img class="autograph" src="../imgs/autograph.png" alt="" />
      </div>
      <div class="desc">
        {{ photos[activeIndex - 1]["desc"] }}
      </div>
    </section>
    <section class="swiper-wrap">
      <swiper ref="mySwiper" :options="pageOptions">
        <swiper-slide v-for="(item, index) in photos" :key="index">
          <img :src="item.url" alt="" />
        </swiper-slide>
      </swiper>
      <img class="left" src="../imgs/left.png" @click="slidePrev" alt="" />
      <img class="right" src="../imgs/right.png" @click="slideNext" alt="" />
    </section>
  </div>
</template>

<script>
export default {
  data() {
    const _this = this;
    return {
      activeIndex: 1,
      pageOptions: {
        loop: true,
        on: {
          slideChange: function (a) {
            if (this.activeIndex == _this.photos.length + 1) {
              _this.activeIndex = 1;
            } else {
              _this.activeIndex = this.realIndex + 1;
            }
          },
        },
      },
      photos: [
        {
          url: require("@/views/pc/leaf/imgs/叶子教师-DJ.png"),
          smUrl: require("@/views/pc/leaf/imgs/叶子教师头像-DJ.png"),
          name: "DJ",
          work: "高级GUI讲师",
          desc: "GUI教研负责人，资深GUI讲师，13年开发经验。曾供职于第九城市、新浪微博。主导研发《武动乾坤》、《热血无双》、《全民足球》等项目。教学风格稳、准、狠。玩弄多种美术风格于股掌之中。对学生要求严格，口碑优异。",
        },
        {
          url: require("@/views/pc/leaf/imgs/叶子教师-花花.png"),
          smUrl: require("@/views/pc/leaf/imgs/叶子教师头像-花花.png"),
          name: "花花",
          work: "高级GUI讲师",
          desc: "资深GUI讲师，9年游戏项目经验，深度参与过网易一线项目，如《率土之滨》、《天下》、《蛋蛋派对》等。尤为擅长乙女和二次元风格。阳光、善良、正能量的小姐姐，是广受同学爱戴的教师。",
        },
        {
          url: require("@/views/pc/leaf/imgs/叶子教师-小天.png"),
          smUrl: require("@/views/pc/leaf/imgs/叶子教师头像-小天.png"),
          name: "小天",
          work: "高级GUI讲师",
          desc: "资深GUI讲师，9年游戏项目经验，曾供职于EA。深度参与过《王牌竞速》、《武林闲侠》、《原神》等项目。教学风格洒脱自然，有着卓越的集体意识和组织能力，被学员亲切的称为阳光教师。",
        },
        {
          url: require("@/views/pc/leaf/imgs/叶子教师-业荣.png"),
          smUrl: require("@/views/pc/leaf/imgs/叶子教师头像-业荣.png"),
          name: "业荣",
          work: "高级GUI讲师",
          desc: "资深GUI讲师，超10年的游戏研发经验，拥有多平台研发背景。主导及参与过多款大型端游、手游项目。教学风格细致耐心，善于循序渐进的帮助学员成长。被学员称为最可靠的教师。",
        },

        {
          url: require("@/views/pc/leaf/imgs/叶子教师-皮皮.png"),
          smUrl: require("@/views/pc/leaf/imgs/叶子教师头像-皮皮.png"),
          name: "皮皮",
          work: "高级GUI讲师",
          desc: "资深GUI讲师，8年从业经验，2021年参与并获得了GGAC年度大赛GUI品类银奖。为人耐心和善，性格热情，励志成为UI界私人教练。被学员亲切的称为最积极有活力的教师。",
        },
        {
          url: require("@/views/pc/leaf/imgs/叶子教师-骨头.png"),
          smUrl: require("@/views/pc/leaf/imgs/叶子教师头像-骨头.png"),
          name: "骨头",
          work: "高级GUI讲师",
          desc: "资深GUI讲师，曾就职腾讯魔方工作室。参与过腾讯《QQ宠物》，《洛克王国》，《火影忍者OL》等项目。擅长休闲游戏和二次元风格。元气与呆萌并存，因细致、认真的教学方式被广大同学们喜爱。",
        },
        {
          url: require("@/views/pc/leaf/imgs/叶子教师-良木.png"),
          smUrl: require("@/views/pc/leaf/imgs/叶子教师头像-良木.png"),
          name: "良木",
          work: "高级动效讲师",
          desc: "从业10年的东北汉子，资深GUI动效教师，资深动特效设计师，曾供职于字节跳动、心动，《英雄联盟》、《拳皇》、《萃星物语》等项目。励志培养出更多复合型设计人才，讲课风趣幽默，激情满满。",
        },
        {
          url: require("@/views/pc/leaf/imgs/叶子教师-黑黑.png"),
          smUrl: require("@/views/pc/leaf/imgs/叶子教师头像-黑黑.png"),
          name: "黑黑",
          work: "高级动效讲师",
          desc: "GUI动效教师，4年老搬砖。曾参与《黑潮之上》、《率土之滨》、《非人学园》、《王牌竞速》等项目。讲课风格清晰明快，直击要点，十分受年轻学员的欢迎。",
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-one {
  height: 100%;
  overflow: hidden;
  position: relative;
  .people-info {
    width: rem(350);
    height: rem(600);
    position: absolute;
    left: rem(55);
    bottom: rem(700);
    z-index: 100;
    .name {
      height: rem(220);
      position: relative;
      transform: scale(1.5);
      transition: transform 0.3s, opacity 0.3s;
      color: #fff;
      opacity: 0;
      .na {
        font-size: 28px;
        position: absolute;
        font-weight: bold;
        left: rem(10);
        letter-spacing: 2px;
        top: rem(50);
        transform: rotate(-8deg);
        z-index: 100;
      }
      .wk {
        font-size: 12px;
        position: absolute;
        color: #ccc;
        font-weight: 600;
        left: rem(140);
        top: rem(90);
        width: 100%;
        z-index: 2;
        transform: rotate(-24deg) scale(0.9);
      }
      .line {
        width: rem(313);
        position: absolute;
        left: rem(0);
        z-index: 10;
      }
      .teacher {
        width: rem(157);
        position: absolute;
        left: rem(140);
        top: rem(60);
        z-index: 10;
      }
      .autograph {
        width: rem(313);
        position: absolute;
        right: rem(20);
        top: rem(15);
      }
    }
    .desc {
      font-size: 13px;
      padding: rem(50) 0 0 0;
      line-height: rem(70);
      color: #f6f6f6;
      font-weight: 600;
      transform: translateY(20px);
      transition: transform 0.5s, opacity 0.6s;
      opacity: 0;
    }
  }
  .swiper-wrap {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: rem(771);
    height: rem(1332);
    transition: opacity 0.6s;
    opacity: 0;
    .swiper-slide {
      width: 100%;
      height: rem(1332);
      overflow: hidden;
      img {
        height: 100%;
      }
    }
    .left {
      width: rem(29);
      position: absolute;
      left: rem(182);
      top: rem(290);
      z-index: 2;
    }
    .right {
      width: rem(29);
      position: absolute;
      top: rem(258);
      right: rem(25);
      z-index: 2;
    }
  }
  .background-wrap {
    img {
      &:nth-of-type(1) {
        width: rem(846);
        position: absolute;
        bottom: rem(-40);
        left: rem(-70);
        transform-origin: -20% 80%;
        transform: rotate(90deg);
        transition: transform 0.3s;
      }
    }
  }
}
.swiper-slide-active {
  .people-info {
    .name {
      transform: scale(1);
      opacity: 1;
      transition-delay: 0.6s;
    }
    .desc {
      transform: translateY(0px);
      opacity: 1;
      transition-delay: 0.3s;
    }
  }
  .swiper-wrap {
    opacity: 1;
    transition-delay: 0.6s;
  }
  .background-wrap {
    img {
      &:nth-of-type(1) {
        transform: translateX(0%);
        transition-delay: 0.3s;
      }
      &:nth-of-type(2) {
        transform: rotate(0deg);
        transition-delay: 0.3s;
      }
    }
  }
}
</style>
