<template>
  <div class="tab-three">
    <div class="student">
      <img src="../imgs/student.png" @click="openInterview" alt="" />
      <p>毕业生采访</p>
    </div>
    <section class="swiper-wrap">
      <swiper ref="mySwiper" :options="pageOptions">
        <swiper-slide v-for="(item, index) in photos" :key="index">
          <img :src="item.url" alt="" @click="openPopup(item, index)" />
        </swiper-slide>
      </swiper>
      <div class="info">
        <img src="../imgs/text.png" alt="" />
        <h2>{{ photos[activeIndex].name }}</h2>
        <p>{{ photos[activeIndex].desc }}</p>
        <p>{{ photos[activeIndex].work }}</p>
      </div>
      <img class="left" src="../imgs/left.png" @click="slidePrev" alt="" />
      <img class="right" src="../imgs/right.png" @click="slideNext" alt="" />
    </section>
    <ul class="pic-tab">
      <li :class="[activeIndex == index && 'active']" v-for="(item, index) in photos" :key="index" @click="slideTo(index)">
        <img :src="item.url" alt="" />
        <p>{{ item.name }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    const _this = this;
    return {
      activeIndex: 0,
      pageOptions: {
        loop: false,
        on: {
          slideChange: function (a) {
            if (this.activeIndex == _this.photos.length + 1) {
              _this.activeIndex = 1;
            } else {
              _this.activeIndex = this.activeIndex;
            }
          },
        },
      },
      photos: [
        {
          url: require("@/views/pc/leaf/imgs/线下实体班.png"),
          name: "线下实体班",
          desc: "1V5最优师生比",
          work: "根据当前在研项目需求针对性培养",
          detail:
            "<p>1V5最优师生比。</p><p>专项指导，对当前一线大厂的工作流程进行拆解。</p><p>贴合在研项目设计理念，根据当前在研项目需求针对性培养。</p>",
        },
        {
          url: require("@/views/pc/leaf/imgs/网络实战班.png"),
          name: "网络实战班",
          desc: "真实的项目经验和规范",
          work: "做出项目需要的动人设计",
          detail:
            "<p>真实的项目经验和规范。</p><p>大厂的研究方向才是目前应该学习的点。</p><p>合理运用脑图，情绪板，做出项目需要的动人设计。</p>",
        },
        {
          url: require("@/views/pc/leaf/imgs/网络创作班.png"),
          name: "网络创作班",
          desc: "理解UE,合理的运用交互技",
          work: "打造属于自己的视觉语言",
          detail:
            "<p>抄袭？不可以！</p><p>如今的测试题越来越要求独立新颖的UI设计，UI是和原画拼塑造么？</p><p>还是和平面拼构成？理解UE,合理的运用交互技巧，打造属于自己的视觉语言。</p>",
        },
        {
          url: require("@/views/pc/leaf/imgs/网络动效班.png"),
          name: "网络动效班",
          desc: "0基础手把手教学",
          work: "高效达到行业标准",
          detail:
            "<p>0基础手把手教学,适合转行和无经验同学。</p><p>一线大咖直播授课，教师全部来自大厂。</p><p>通过20+热门项目案例讲解，高效达到行业标准。</p>",
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
    },
    openPopup(item, index) {
      const temp = {
        files: [{ url: item.url }],
        title: item.name,
        html: item.detail,
      };
      this.$emit("open-popup", temp);
    },
    openInterview() {
      this.$emit("open-interview");
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-three {
  height: 100%;
  overflow: hidden;
  position: relative;
  .student {
    width: rem(180);
    position: absolute;
    left: rem(38);
    top: rem(10);
    transform: translateY(rem(-300)) scale(0.8);
    transition: transform 0.3s;
    img {
      width: 100%;
    }
    p {
      color: #fff;
      text-align: center;
      font-size: 10px;
    }
    &:active {
      opacity: 0.7;
    }
  }
  .swiper-wrap {
    width: rem(920);
    margin: rem(298) auto 0;

    .swiper-slide {
      height: rem(900);
      width: 100%;
      img {
        height: 90%;
        margin-left: rem(125);
        transform: scale(1.5);
        opacity: 0;
        transition: transform 0.3s, opacity 0.3s;
      }
    }
    .info {
      color: #9a9a9a;
      font-size: rem(36);
      letter-spacing: 3px;
      padding-left: rem(120);
      opacity: 0;
      transition: opacity 0.5s;
      position: relative;
      img {
        width: rem(456);
        position: absolute;
        top: rem(-55);
        left: rem(40);
      }
      h2 {
        color: #fff;
        transform: rotate(-10deg) translateY(rem(-100));
        position: relative;
      }
      p {
        &:nth-of-type(1) {
          transform: rotate(-1deg);
          padding-left: rem(100);
        }
        &:nth-of-type(2) {
          transform: rotate(2deg);
          margin-top: rem(40);
          padding-left: rem(100);
        }
      }
    }
    .left {
      width: rem(34);
      top: rem(655);
      position: absolute;
      left: rem(84);
      z-index: 99;
      opacity: 0;
      transition: opacity 0.5s;
    }
    .right {
      width: rem(34);
      top: rem(655);
      position: absolute;
      right: rem(84);
      z-index: 99;
      opacity: 0;
      transition: opacity 0.5s;
    }
  }
  .pic-tab {
    height: rem(270);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    bottom: rem(100);
    left: rem(69);
    li {
      width: rem(182);
      margin-right: rem(20);
      opacity: 0.3;
      &:active {
        opacity: 0.7;
      }
      transform: translateY(rem(400));
      transition: transform 0.3s;
      img {
        width: 100%;
      }
      p {
        color: #fff;
        font-size: rem(24);
      }
      &:nth-of-type(1) {
        p {
          transform: rotate(-5deg);
        }
        img {
          transform: rotate(3deg);
        }
      }
      &:nth-of-type(2) {
        p {
          transform: rotate(3deg);
        }
      }
      &:nth-of-type(3) {
        p {
          transform: rotate(2deg);
        }
        img {
          transform: rotate(5deg);
        }
      }
      &:nth-of-type(4) {
        p {
          transform: rotate(3deg);
        }
        img {
          transform: rotate(3deg);
        }
      }
      &.active {
        opacity: 1;
      }
    }
  }
}
.swiper-slide-active {
  .student {
    transform: translateY(0) scale(0.8);
    transition-delay: 0.5s;
  }
  .tab-three {
    .swiper-wrap {
      .swiper-slide {
        img {
          transform: scale(1);
          opacity: 1;
          transition-delay: 0.3s;
        }
      }
      .info {
        opacity: 1;
        transition-delay: 0.5s;
      }
      .left {
        opacity: 1;
        transition-delay: 0.6s;
      }
      .right {
        opacity: 1;
        transition-delay: 0.6s;
      }
    }
    .pic-tab {
      li {
        transform: translateY(0);
        &:nth-of-type(1) {
          transition-delay: 0.3s;
        }
        &:nth-of-type(2) {
          transition-delay: 0.4s;
        }
        &:nth-of-type(3) {
          transition-delay: 0.5s;
        }
        &:nth-of-type(4) {
          transition-delay: 0.6s;
        }
      }
    }
  }
}
</style>
